// 排序规则
export function sortby(name, minor, sort) {
    // eslint-disable-next-line consistent-return
    return function (o, p) {
        let a; let b;
        if (o && p && typeof o === 'object' && typeof p === 'object') {
            a = o[name];
            b = p[name];
            if (a === b) {
                return typeof minor === 'function' ? minor(o, p) : 0;
            }
            if (typeof a === typeof b) {
                a = parseFloat(a.toString().replace(/,/g, ''));
                b = parseFloat(b.toString().replace(/,/g, ''));
                if (sort === 'ascending') {
                    return a < b ? -1 : 1;
                } if (sort === 'descending') {
                    return a < b ? 1 : -1;
                }

            }
            if (sort === 'ascending') {
                return typeof a < typeof b ? -1 : 1;
            } if (sort === 'descending') {
                return typeof a < typeof b ? 1 : -1;
            }

        } else {
            // eslint-disable-next-line no-throw-literal
            throw 'error';
        }
    };
}
