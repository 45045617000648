<template>
    <div class="tableList" v-loading="loading">
        <div class="tableListBox">
            <!-- table头部区域 -->
            <div class="tableHeader" v-if="isShoawTableHeader">
                <!-- 高级查询 -->
                <query
                    class="tableHeader_advancedQuery"
                    v-if="isAdvancedQuery && queryCriteria && (queryCriteria.queryData || []).length > 0"
                    :query-criteria="queryCriteria"
                    @getTableList="getTableList"
                ></query>
                <!-- 列表头部按钮 -->
                <div class="el_left tableHeader_btn">
                    <template v-for="(item, index) in buttons">
                        <el-button
                            size="mini"
                            class="btns"
                            v-if="item.hidden"
                            :class="item.btnclass"
                            :key="`tableHeader_btn_${index}`"
                            @click="tableBtnCiick(item.clientscript)"
                        >
                            <i :class="item.imageurl"></i>
                            {{ item.name }}
                        </el-button>
                    </template>
                    <el-button
                        class="btnsStyle1"
                        v-if="excelBtn.show"
                        @click="exportExcel()"
                    >
                        <i class="iconfont icondaochu"></i>
                        <span>{{ excelBtn.name }}</span>
                    </el-button>
                </div>
                <!-- 自定义列表列 -->
                <div class="el_right tableHeader_setUp">
                    <el-button v-popover:popover class="btnsStyle2 iconfont iconshezhi"></el-button>
                    <el-popover
                        width="540"
                        ref="popover"
                        trigger="click"
                        title="自定义列表项"
                        v-model="isPopover"
                    >
                        <div class="tablePopover">
                            <div>
                                <el-checkbox-group v-model="selectListItem">
                                    <template v-for="(item, index) in listDisplayItems">
                                        <el-checkbox :key="`tablePopover_${index}`" :label="item.field">
                                            {{ item.title }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                            <div class="el_right">
                                <el-button size="mini" @click="isPopover = false">
                                    取消
                                </el-button>
                                <el-button size="mini" type="primary" @click="confirmListItem">
                                    确定
                                </el-button>
                            </div>
                        </div>
                    </el-popover>
                </div>
                <!-- 列表提示 -->
                <div class="el_right tableHeader_listInfo" v-if="helper">
                    <i
                        @mouseover="ishelper = true;"
                        @mouseout="ishelper = false"
                        class="iconfont iconwenhao"
                    ></i>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-if="ishelper" v-html="helper"></div>
                </div>
                <!-- 后台配置查询 -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="el_right tableHeader_query" v-html="tablehead"></div>
                <!-- 普通查询 -->
                <div class="el_right tableHeader_generalQuery" v-if="isGeneralQuery">
                    <el-input placeholder="请输入搜索内容..." v-model="generalQueryText" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="generalQuery"></el-button>
                    </el-input>
                </div>
            </div>
            <!-- table主体 -->
            <div class="tableContent">
                <div class="table-bg infinite-list-wrapper">
                    <el-table
                        stripe
                        border
                        height="100%"
                        v-scroll="scrollFn"
                        :ref="tableRefNmame"
                        :data="list"
                        :show-summary="isShowTotal"
                        :highlight-current-row="true"
                        :summary-method="getSummaries"
                        :class="['table-list', {'sum-Tble': isShowTotal}]"
                        :cell-style="cellStyle"
                        :header-cell-style="headerCellStyle"
                        @selection-change="handleSelectionChange"
                        @row-click="selateTable"
                        @sort-change="sortChange"
                    >
                        >
                        <el-table-column
                            width="55"
                            key="select"
                            type="selection"
                            v-if="showCheckBox"
                        >
                        </el-table-column>
                        <el-table-column
                            width="55"
                            label="选择"
                            v-if="showRadio"
                        >
                            <template slot-scope="scope">
                                <el-radio
                                    v-model="radio"
                                    :label="scope.row"
                                    @change.native="getTemplateRow(scope.$index,scope.row)"
                                >
                                    <i></i>
                                </el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="55"
                            label="序号"
                            type="index"
                            v-if="isShowNo"
                            :fixed="showNoFixed"
                            :index="indexMethod"
                        >
                        </el-table-column>
                        <template v-for="(column, index) in columns">
                            <el-table-column
                                align="center"
                                v-if="!column.type && column.visible"
                                :prop="column.field"
                                :label="column.title"
                                :title="column.title"
                                :width="column.width"
                                :key="`table_column_${index}`"
                                :fixed="isFixed(column.clickToSelect)"
                                :sortable="column.sortable ? 'custom' : false"
                            >
                                <template slot-scope="scope">
                                    <!--列表列定义点击事件-->
                                    <div
                                        v-if="column.click"
                                        :style="column.style"
                                        :title="scope.row[column.field]"
                                        :class="{ nowrap: column.isAutoWrap }"
                                        @click="btnClickfunc(column, scope.row, column.desc)"
                                    >
                                        {{ scope.row[column.field] }}
                                    </div>
                                    <!--后台自定义标签渲染-->
                                    <template v-else-if="column.isCustom">
                                        <!-- eslint-disable-next-line vue/no-v-html -->
                                        <div v-html="scope.row[column.field]" :style="column.style" :class="{ nowrap: column.isAutoWrap }"></div>
                                    </template>
                                    <div
                                        v-else
                                        :style="column.style"
                                        :title="scope.row[column.field]"
                                        :class="{ nowrap: column.isAutoWrap }"
                                    >
                                        {{ scope.row[column.field] }}
                                    </div>
                                </template>
                            </el-table-column>
                        </template>
                    </el-table>
                </div>
                <!-- 列表分页 -->
                <div class="table-page">
                    <div class="el_right">
                        <div class="noMore" v-if="isPagination == false && total > 0">
                            每页{{ requestData.limit }}条 已加载{{ requestData.page + 1 }}/{{ pageCount }}页  共{{ total }}条
                        </div>
                        <div class="el_left multipleSelected" v-if="showCheckBox">
                            已选 <span>{{ tableSelectData.length }}</span> 条
                        </div>
                        <el-pagination
                            background
                            class="el_left"
                            v-if="isPagination == true"
                            layout="total, sizes, prev, pager, next, jumper"
                            :current-page="requestData.page + 1"
                            :total="total"
                            :page-size="requestData.limit"
                            :page-sizes="pageSizes"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './css/index.stylus';
import store from './../../store';
import { sortby } from './js/util';
import { eventBus } from '@/common/eventBus.ts';

// 高级查询
import query from './queryCriteria.vue';

export default {
    name: 'table-list',
    components: {
        query,
    },
    directives: {
        scroll: {
            inserted: function (el, binding) {
                const cb = binding.value;
                const selectWrap = el.querySelector('.el-table__body-wrapper');
                selectWrap.addEventListener('mousewheel', function (e) {
                    const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
                    const direction = e.deltaY > 0 ? 'down' : 'up';
                    cb(selectWrap, direction, scrollDistance);
                });
            },
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            // 表头数据集合
            columns: [],
            // 表单ref
            tableRefNmame: '',
            // 是否显示复选框
            showCheckBox: false,
            // 是否显示单选框
            showRadio: false,
            // 是否显示序号列
            isShowNo: false,
            // 序号列名称
            showNoName: '',
            // 序号列是否固定
            showNoFixed: false,
            // 是否显示合计行
            isShowTotal: false,
            // 合计模式（true合计当前页，false合计所有页数据）
            isPageTotal: true,
            // 合计数据
            totalData: [],
            // 数据集合
            list: [],
            // 数据总条数
            total: 0,
            // 列表请求id
            tableCode: '',
            // 列表视图编码
            ViewCode: '',
            // 列表请求附加参数
            queryString: [],
            // 列表数据请求参数
            requestData: {
                cacheKey: '',
                code: '',
                limit: 0,
                page: 0,
                // 自定义排序 
                // "customsort":"id|asc"  正序 
                // "customsort":"id|desc" 倒序
                customsort: '',
                // 不显示列表项[导出excel添加]
                filterFields: '',
            },
            // 分页模式（true页码分页，false滚动分页）
            isPagination: false,
            // 总页数
            pageCount: 0,
            // 是否是最后一页
            isLastPage: false,
            // 头部按钮集合
            buttons: '',
            // 头部查询区域
            tablehead: '',
            // 列表简介
            helper: '',
            // 是否显示列表简介
            ishelper: false,
            // 配置脚本
            tableScript: '',
            // 配置方法脚本
            eventUrl: '',
            // 列表选择数据集合
            tableSelectData: [],
            // 单选选中数据
            radio: '',
            // 是否显示普通查询
            isGeneralQuery: false,
            // 普通查询，输入内容
            generalQueryText: '',
            // 是否显示高级查询
            isAdvancedQuery: false,
            // 高级查询条件数据
            queryCriteria: {},
            // excel按钮参数
            excelBtn: {
                show: false,
                name: '导出excel',
            },
            // 自定义列表项数据
            listDisplayItems: [],
            // 已选中显示项
            selectListItem: [],
            // 列表项选择弹出层状态
            isPopover: false,
            // 列表每页显示条数选项
            pageSizes: [],
        };
    },
    props: {
        code: {
            type: [Array, Object, String ],
        },
        extr: {
            type: [Object],
        },
    },
    watch: {
        $route: 'refreshTable',
        extr: {
            handler: function () {
                // 转发后台配置方法，添加参数
                window.addParams = this.addParams;
                this.refreshTable();
            },
            deep: true,
        },
    },
    created() {},
    computed: {},
    mounted() {
        // 初始化列表
        this.refreshTable();
        // 转发后台配置列表查询事件
        window.listQuery = this.listQuery;
        // 转发后台配置方法，返回列表选中数据
        window.selectData = this.selectData;
        // 转发后台配置方法，返回列表配置参数
        window.renturnQueryString = this.renturnQueryString;
        // 转发后台配置方法，列表刷新
        window.updateTable = this.updateTable;
        // 转发后台配置方法，事件
        window.eventBus = eventBus;
        // 转发后台配置方法，添加参数
        window.addParams = this.addParams;
        // 添加事件总线eventBus/table刷新方法
        eventBus.$on('updateTable', () => {
            // 列表选择数据集合
            this.tableSelectData = [];
            this.getTableList();
        });
    },
    methods: {
        // 表格单元格自定义样式{row, column, rowIndex, columnIndex}
        cellStyle({ columnIndex }) {
            if (this.columns[columnIndex] && this.columns[columnIndex].cellStyle) {
                return this.columns[columnIndex].cellStyle;
            }
            return null;
        },
        headerCellStyle({ columnIndex }) {
            if (this.columns[columnIndex] && this.columns[columnIndex].cellStyle) {
                return this.columns[columnIndex].cellStyle;
            }
            return null;
        },
        // 点击行选中
        selateTable(row) {
            if (this.showCheckBox) {
                this.$refs[this.tableRefNmame].toggleRowSelection(row);
                this.tableSelectData = this.$refs[this.tableRefNmame].selection;
            } else if (this.showRadio) {
                this.radio = row;
                this.tableSelectData = row;
            }
        },
        refreshTable() {
            const _that = this;
            // 添加回车事件，回车搜索
            document.onkeydown = function () {
                const key = window.event.keyCode;
                if (key === 13) {
                    _that.generalQuery();
                }
            };
            store.commit('deleteRecordOpenList', this.tableRefNmame);
            // 初始化页面是否加载完数据
            this.isLastPage = false;
            // 初始化列表数据请求参数
            this.requestData = {};
            this.requestData.cacheKey = Math.floor(Math.random() * 1000000000000 + 1);
            this.requestData.code = '';
            this.requestData.limit = 0;
            this.requestData.page = 0;
            this.requestData.customsort = '';
            // 自定义列表项数据
            this.listDisplayItems = [];
            // 已选中显示项
            this.selectListItem = [];
            // 高级查询条件数据
            this.queryCriteria = {};
            // 判断有code值，为弹出列表
            if (this.code) {
                if (this.code.TableCode) {
                    // 列表编码赋值
                    this.tableCode = this.code.TableCode;
                }
                if (this.code.QueryString) {
                    // 附加参数赋值
                    this.queryString = this.code.QueryString;
                }
                if (this.code.ViewCode) {
                    // 视图编码赋值
                    this.ViewCode = this.code.ViewCode;
                }
            // 判断有meta值，为后台配置列表
            } else if (this.$route.meta) {
                this.tableCode = this.$queryString(this.$route.meta.TableCode, 'TableCode');
            } else {
                this.$message.error('配置错误！');
            }
            this.requestData.code = this.tableCode;
            // 初始化整个列表
            this.getTableHeader();
        },
        // 鼠标滚动加载更多
        scrollFn: function (obj, direction, scrollTop) {
            if (this.isPagination === true || direction === 'up' || this.loading === true || this.isLastPage === true) {return;}
            if (scrollTop <= 150) {
                if (this.list.length < this.total) {
                    this.requestData.page++;
                    this.getTableList();
                } else {
                    this.isLastPage = true;
                }
            }
        },
        // 列表头部数据获取
        getTableHeader() {
            // 请求loading状态
            this.loading = true;
            this.list = [];
            // 清空搜索框内容
            this.generalQueryText = '';
            this.isShowTotal = false;
            let getUrl = '/interfaceApi/basicplatform/tabledetail/' + this.tableCode;
            // 判断是否有视图编码
            if (this.ViewCode !== '') {
                getUrl = getUrl + '/' + this.ViewCode;
            }
            this.columns = [];
            this.$axios
                .get(getUrl)
                .then(res => {
                    // 表单ref
                    this.tableRefNmame = 'tableRef_' + res.view.id;
                    // 记录打开列表
                    store.commit('recordOpenList', {
                        tableObj: this,
                        tableRefNmame: this.tableRefNmame,
                    });
                    // 复选框
                    this.showCheckBox = res.view.showCheckBox;
                    // 单选框
                    this.showRadio = res.view.showRadio;
                    // 序号是否显示
                    this.isShowNo = res.view.isShowNo;
                    // 是否固定序号列
                    this.showNoFixed = res.view.showNoFixed;
                    // 是否显示合计
                    this.isShowTotal = res.view.isShowTotal;
                    // 合计模式，true合计当前页，false合计所有页数据
                    this.isPageTotal = res.view.isPageTotal;
                    // 分页模式,true页码分页，false滚动分页
                    this.isPagination = res.view.pagination;
                    // 自定义序号名称
                    this.showNoName = res.view.showNoName;
                    // 列表数据请求参数
                    this.requestData.limit = res.view.pageSize;
                    // 每页显示数下拉选项赋值
                    this.pageSizesValue(res.view.pageSize);
                    // 配置脚本
                    this.tableScript = res.script;
                    // 按钮
                    this.buttons = res.view.buttons;
                    // excel按钮参数
                    this.excelBtn = {
                        show: res.view.isShowExportExcel,
                        name: res.view.exportExcelName,
                    };
                    // 查询区域
                    this.tablehead = res.tablehead;
                    // 简介
                    this.helper = res.helper;
                    // 是否显示普通查询
                    this.isGeneralQuery = res.view.search;
                    // 是否显示高级查询
                    this.isAdvancedQuery = res.view.advancedSearch;
                    // 加载表头显示项
                    this.tableHeaderDisplayItem(res.view.columns);
                    // 加载后台配置脚本
                    this.loadExternalFile(this.tableScript);
                    if (Object.keys(window.queryCriteria).length > 0) {
                        this.queryCriteria = window.queryCriteria;
                    } else {
                        this.getTableList();
                        this.queryCriteria = {};
                    }
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 列表数据
        getTableList(parameter, type) {
            this.loading = true;
            this.radio = '';
            // 列表数据请求地址
            let postUrl = '/interfaceApi/basicplatform/tabledata';

            // 判断有code值，为弹出列表，弹出列表有附加参数
            if (this.code && this.code.QueryString) {
                postUrl += '?' + this.code.QueryString;
            }
            // parameter配置查询参数
            if (parameter) {
                parameter.forEach(item => {
                    this.requestData[item.key] = item.value;
                });
            }
            // 类型是generalQuery，是普通查询,请求头里添加searchKey
            if (type === 'generalQuery') {
                this.requestData.searchKey = this.generalQueryText;
            }
            this.$axios
                .post(postUrl, this.requestData)
                .then(res => {
                    if (this.isPagination === true) {
                        this.$set(this, 'list', res.rows);
                    } else {
                        this.list.push(...res.rows);
                    }
                    // 数据总条数
                    this.total = res.total;
                    // 总页数
                    this.pageCount = Math.ceil(this.total / this.requestData.limit);
                    // 配置方法脚本
                    this.eventUrl = res.tableUrl;
                    // true 拉取合计数据
                    if (this.isShowTotal) {
                        // 获取合计数据
                        this.getTotalData();
                    } else {
                        this.loading = false;
                    }
                    this.$nextTick(() => {
                        if (this.$refs[this.tableRefNmame]) {
                            this.$refs[this.tableRefNmame].doLayout();
                        }
                    });
                    // 强制刷新
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 加载表头显示项
        tableHeaderDisplayItem(res) {
            let cacheData = localStorage.getItem('listDisplayColumn');
            if ((cacheData || '') !== '') {
                cacheData = JSON.parse(cacheData);
            } else {
                cacheData = [];
            }
            const row = cacheData.filter(item => item.tableNmame === this.tableRefNmame);
            if (row.length > 0) {
                const selectListItemList = res.filter(item => row[0].tableDisplayItem.indexOf(item.field) === -1);
                this.selectListItem = selectListItemList.map(item => item.field);
                this.columns = res.filter(item => this.selectListItem.indexOf(item.field) > -1);

                this.requestData.filterFields = row[0].tableDisplayItem.toString();
            } else {
                this.selectListItem = res.map(item => item.field);
                // 表头数据
                this.columns = res;
            }
            this.listDisplayItems = res.filter(item => item.visible);
        },

        // 加载配置脚本
        loadExternalFile(tableScript) {
            $('head .tableScriptFunction').remove();
            $('head .tableScriptJs').remove();
            $('head .tableScriptcStyle').remove();
            window.queryCriteria = {};
            if (tableScript) {
                if (tableScript.js) {
                    tableScript.js = tableScript.js.split(',');
                    tableScript.js.forEach(function (item) {
                        const script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.src = item;
                        script.className = 'tableScriptJs';
                        document.getElementsByTagName('head')[0].appendChild(script);
                    });
                }
                if (tableScript.function) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.className = 'tableScriptFunction';
                    script.text = tableScript.function;
                    document.getElementsByTagName('head')[0].appendChild(script);
                }
                if (tableScript.style) {
                    tableScript.style = tableScript.style.split(',');
                    tableScript.style.forEach(function (item) {
                        const cssLink = document.createElement('link');
                        cssLink.setAttribute('rel', 'stylesheet');
                        cssLink.setAttribute('type', 'text/css');
                        cssLink.setAttribute('class', 'tableScriptcStyle');
                        cssLink.setAttribute('href', item);
                        document.getElementsByTagName('head')[0].appendChild(cssLink);
                    });
                }
                if (tableScript.css) {
                    const cssStyle = document.createElement('style');
                    cssStyle.innerHTML = tableScript.css;
                    document.head.appendChild(cssStyle);
                }
            }
        },
        // 执行列表表头按钮事件
        tableBtnCiick(clientscript) {
            // eslint-disable-next-line no-eval
            eval(clientscript);
        },
        // 后台配置列表查询
        listQuery(data) {
            this.getTableList(data);
        },
        // 列表普通查询
        generalQuery() {
            this.list = [];
            this.isLastPage = false;
            if (this.generalQueryText !== '') {
                this.requestData.page = 0;
                this.getTableList('', 'generalQuery');
            } else {
                this.requestData = {
                    page: 0,
                    code: this.requestData.code,
                    limit: this.requestData.limit,
                    cacheKey: this.requestData.cacheKey,
                    customsort: this.requestData.customsort,
                    filterFields: this.requestData.filterFields,
                };
                this.getTableList();
            }
        },
        // 返回table 选择数据
        selectData(returnSelectData) {
            returnSelectData(this.tableSelectData, this);
        },
        // 返回列表请求附加参数
        renturnQueryString(name) {
            return this.$queryString(this.queryString, name);
        },
        // 计算列表序号
        indexMethod(index) {
            return this.requestData.page * this.requestData.limit + index + 1;
        },
        // 排序
        sort_change(col) {
            this.formatData.sort(sortby(col.prop, '', col.order));
        },
        // 点击页码
        handleCurrentChange(cur) {
            this.radio = '';
            this.requestData.page = cur - 1;
            if (this.generalQueryText !== '') {
                this.getTableList('', 'generalQuery');
            } else {
                this.getTableList();
            }
            // 强制刷新
            this.$forceUpdate();
        },
        // 设置每页显示多少条
        handleSizeChange(pagesize) {
            // this.limit = pagesize;
            this.requestData.limit = pagesize;
            this.getTableList();
        },
        // 后台配置列点击事件
        // eslint-disable-next-line no-unused-vars
        btnClickfunc(column, row) {
            if (column.click) {
                // eslint-disable-next-line no-eval
                eval(column.click);
            }
        },
        // 单选获取选中数据
        getTemplateRow() {
            this.tableSelectData = this.radio;
        },
        // 多选选中数据集合
        handleSelectionChange() {
            this.tableSelectData = this.$refs[this.tableRefNmame].selection;
        },
        // 后台配置，刷新列表
        updateTable() {
            // 列表选择数据集合
            this.tableSelectData = [];
            this.getTableList();
        },
        // 后台配置方法，需要取extr参数
        addParams(callbackFn) {
            callbackFn(this);
        },
        // 是否固定列
        isFixed(data) {
            let fixed = false;
            if (data !== '') {
                fixed = data;
            }
            return fixed;
        },
        // 导出excel
        exportExcel(url, data = {}) {
            return new Promise(() => {
                this.$axios.post('/interfaceApi/basicplatform/tabledata/export/excel', this.requestData, { responseType: 'arraybuffer' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
                        const fileName = '导出数据.xlsx';
                        if ('download' in document.createElement('a')) { // 非IE下载
                            const elink = document.createElement('a');
                            elink.download = fileName;
                            elink.style.display = 'none';
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else { // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    }).catch(() => {
                        if (data.Vue) {
                            this.$message.error('系统异常');
                        }
                    });
            });
        },
        // 确认选择列表显示项
        confirmListItem() {
            this.columns = this.listDisplayItems.filter(x => this.selectListItem.includes(x.field));
            const tableHideFieldList = this.listDisplayItems.filter(item => this.selectListItem.indexOf(item.field) === -1);
            const tableHideFieldIDs = tableHideFieldList.map(item => item.field);
            this.requestData.filterFields = tableHideFieldIDs.toString();

            let cacheData = localStorage.getItem('listDisplayColumn');
            if ((cacheData || '') !== '') {
                cacheData = JSON.parse(cacheData);
            } else {
                cacheData = [];
            }
            const row = cacheData.filter(item => item.tableNmame === this.tableRefNmame);
            if (row.length === 0) {
                cacheData.push({
                    tableNmame: this.tableRefNmame,
                    tableDisplayItem: tableHideFieldIDs,
                });
            } else if (this.listDisplayItems.length === this.selectListItem.length) {
                cacheData.splice(cacheData.findIndex(item => item.tableNmame === this.tableRefNmame), 1);
            } else {
                cacheData.splice(
                    cacheData.findIndex(item => item.tableNmame === this.tableRefNmame),
                    1,
                    {
                        tableNmame: this.tableRefNmame,
                        tableDisplayItem: tableHideFieldIDs,
                    }
                );
            }
            localStorage.setItem('listDisplayColumn', JSON.stringify(cacheData));
            this.isPopover = false;
        },
        // 获取合计数据
        async getTotalData() {
            await this.$axios
                .post('/interfaceApi/basicplatform/tabledata/total', this.requestData)
                .then(res => {
                    this.totalData = res;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 合计行数据处理
        getSummaries() {
            const sums = [];
            const newColumns = [];
            this.columns.forEach(item => {
                if (item.visible) {
                    newColumns.push(item);
                }
            });
            newColumns.forEach(() => {
                sums.push(' ');
            });
            const newTotalData = Object.keys(this.totalData || []);
            let nub = 0;
            if (this.isShowNo) {
                nub = nub + 1;
            }
            if (this.showRadio || this.showCheckBox) {
                nub = nub + 1;
            }
            newTotalData.forEach(item => {
                newColumns.forEach((k, i) => {
                    if (item === k.field) {
                        sums[i + nub] = this.totalData[item];
                    }
                });
            });
            sums[0] = '合计';
            return sums;
        },
        // 每页显示数下拉选项赋值
        pageSizesValue(nub) {
            this.pageSizes = [];
            if (!nub) {
                this.pageSizes = [15, 30, 45, 60, 80, 100];
            } else {
                for (let i = 1; i < 7; i++) {
                    this.pageSizes.push(nub * i);
                }
            }
        },
        // 服务端通用排序
        sortChange({ prop, order }) {
            if (order) {
                const sort = order === 'ascending' ? 'asc' : 'desc';
                this.requestData.customsort = prop + '|' + sort;
            } else {
                this.requestData.customsort = '';
            }

            eventBus.$emit('updateTable');
        },
        // 是否显示列表header
        isShoawTableHeader() {
            if (
                this.buttons.length > 0 || this.helper !== '' || this.tablehead !== ''
                || this.isGeneralQuery || this.isAdvancedQuery || this.excelBtn.show
            ) {
                return true;
            }
            return false;
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus
        eventBus.$off('updateTable');
        // 删除记录打开列表
        store.commit('deleteRecordOpenList', this.tableRefNmame);
    },
};
</script>

<style lang="stylus">
</style>
